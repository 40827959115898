<template>
<div class="idea" v-loading="pageLoading">
 <div class="ideaHeader">
    <div class="title">{{info.name}}</div>
    <div class="auther">
      <img class="autherImg" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BB%84%2029%402x.png" alt="" mode="aspectFill"/>
      <div class="autherNm">
        托育高研院官方账号
        <div class="time">
          {{info.updated_at}}
        </div>
      </div>
      {{info.pv_count}}人看过
    </div>
  </div>
  <div class="detail" v-if="info.sound_url">
    
      <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%205%402x.png"/>
      <my-audio ref="audio" @closeAudio="closeAudio" :list="[info]" :isShowAudioPlayer="isShowAudioPlayer"></my-audio>
  </div>
  <div class="content">
      <div v-html="info.content">

      </div>
  </div>
</div>
</template>

<script>
import myAudio from '@/components/Audio'
export default {
  name: "Home",
  components:{myAudio},
  data() {
    return {
      id:'',
      info:{},
      isShowAudioPlayer:true,
      ideaNavData:[],
      pageLoading: true,
      list: [{},{},{}],
      tag: 1,
      classify: 0,
      certificate: [],
      topData: [],
      category: [],
      loading: false,
      finished: false,
      more: false,
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.onLoad);
     this.pageLoading = true
    this.id = this.$route.query.id;
    let res = await this.$api({
      method: 'get',
      url: '/api/silkbag/detail',
      data: {
        id: this.id,
      }
    })
    if (res.code == 0) {
      this.addHistory(this.id);
      if(!res.data) {
        this.pageLoading = false
        return
      }
      res.data.updated_at = res.data.updated_at.slice(0,10)
      res.data.content = res.data.content.replace(/<img[^>]*>/gi, function (match, capture) {
                        return match.replace(/(<img[^>]*)(\/>)/gi, "$1style='max-width:100%;width:auto;height:auto;' $2")
                    })
      this.info = res.data
      
      this.pageLoading = false
      this.$nextTick(()=>{
        console.log(this.$refs)
        this.$refs.audio.getData(0)
        this.isShowAudioPlayer = true
      })
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onLoad)
  },
  computed: {
    plat() {
      return this.$store.state.plat_id
    }
  },
  methods: {
    // 浏览记录
    async addHistory(id) {
        let res = await this.$api({
          method: 'get',
          url: "/api/silkbag/detail/click", 
          data: {
                  id: id,
          }
        });
    },
    closeAudio(){
      this.isShowAudioPlayer = false;
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    next()
  },

};
</script>

<style lang="scss" scoped>
.idea {
  min-height: calc(100vh - 230px);
  .detail img{
    margin: 50px auto;
    display: block;
  }

}
.ideaHeader{
  width: 100vw;
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 17px solid #F5F5F5;
}
.ideaHeader .title{
  font-size: 34px;
  padding-bottom: 30px;
}
.ideaHeader .auther{
   display: flex;
   flex: 1;
   font-size: 26px;
   color: #666666;
}
.ideaHeader .auther .autherNm{
  flex: 1;
  font-size: 28px;
  color: #333333;
}
.ideaHeader .auther img{
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.ideaHeader .auther .time{
  font-size: 22px;
  margin-top: 8px;
  color: #666666;
}
.content{
  padding: 30px;
}
</style>

<style lang="scss">

</style>
